.section-infinite {
  padding-bottom: 40px;
  font-family: "Poppins";
}
.section-title {
  font-size: 2.4rem;
  margin-left: 15px;
  color: rgba(220, 220, 220, 0.9);
  font-weight: 400;
  margin-top: 4rem;
  margin-bottom: 2rem;
}
@media screen and (min-width: 768px) {
  .section-title {
    font-size: 2.5rem;
    margin-left: 23px;
  }
}
.pagination-wrapper {
  margin-top: 20px;
  display: flex;
  align-items: center;
  width: 100vw;
  justify-content: center;
}
.pagination {
  height: 60px;
  width: 96%;
  display: flex;
  align-items: center;
  margin-top: 20px;
  justify-content: space-between;
  box-shadow: 0px 1px 4px rgba(50,50,50,0.9);
  border-radius: 0.45rem;
}
.previous-page-button,
.next-page-button {
  font-size: 15px;
  outline: none;
  border: none;
  color: rgba(240,240,240,0.8);
  width: 150px;
  background-color: transparent;
  cursor: pointer;
}
.btn-pageindex {
  border: none;
  padding: 1rem 2rem;
  border-radius: 0.15rem;
  color: rgba(240,240,240,0.8);
  background: none;
  font-size: 14px;
  cursor: pointer;
}
