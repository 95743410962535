.herocard-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-position: center;
  background-size: cover;
  height: 84vh;
  margin-top: 20px;
  align-items: flex-start;
  padding: 10rem 10rem;
  text-align: left;
  max-width: 100%;
  border-radius: 0.25rem;
  font-weight: 100;
  flex-wrap: wrap;
}



.herocard-wrapper img {
  height: 100%;
  width: 100%;
}

.herocard-animeinfo-wrapper {
  display: flex;
  flex-direction:column;
  gap: 1rem;
  flex-wrap: wrap;
}

.herocard-animeinfo {
  color: rgba(200,200,200,0.9);
  display: flex;
  gap: 2rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  flex-wrap: wrap;
  font-weight: 400;
}

.herocard-animeinfo-title {
  color: white;
  font-size: 4rem;
  width: 40%;
  display: flex;
  /* text-shadow: -2px -2px 1px rgba(240,240,0,1); */
  font-weight: 500;
}
.herocard-animeinfo-description {
  text-align: justify;
  color: rgba(200,200,200,0.7);
  font-family: sans-serif;
  line-height: 1.5;
  font-weight: 300;
  width: 40%;
}

.herocard-btn-watch {
  background-color: rgba(240,240,240,0.9);
  border: none;
  color: rgba(1, 1, 1, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 1px;
  font-family: inherit;
  border-radius: 0.25rem;
  font-size: 2rem;
  /* font-weight: bold; */
  width: 18rem;
  margin-top: 2rem;
  cursor: pointer;
  box-shadow: 2px 2px 4px rgba(10,10,10,0.4);
  transition: 500ms ease;
  
}

.btn-watchnow:hover {
  opacity: 0.8;
  
}


@media screen and (max-width: 768px) {
  .herocard-wrapper {
    height: 80vh;
  }
  .herocard-animeinfo-item {
    font-size: 1.4rem;
  }
  .herocard-animeinfo-title {
    font-size: 2rem;
  }
  .herocard-animeinfo-description {
    font-size: 1rem;
  }
  .herocard-animeinfo-description {
    width: 100%;
    font-size: 1rem;
  }
  .herocard-btn-watch {
    font-size: 1.2rem;
  }
}

@media (max-width: 600px){

  .herocard-animeinfo-wrapper {
    width: 40rem;
  }
}

@media (max-width: 441px) {

  .herocard-animeinfo-wrapper > * {
    flex-wrap: wrap;
  }

  .herocard-animeinfo-wrapper {
    width: 36rem;
    margin: auto 2rem;
    padding: 0;
  }
}

@media (max-width: 396px){

  .herocard-animeinfo-wrapper {
    width: 32rem;
  }
}

@media (max-width: 360px){

  .herocard-animeinfo-wrapper {
    width: 28rem;
  }
}