@media screen and (max-width: 700px) {
  .main-wrapper {
    flex-direction: column;
  }
  .welcome-text {
    display: none;
  }
}
@media screen and (max-width: 375px) {
  .login-container {
    width: 95%;
  }
}

.main-wrapper {
  display: flex;
  min-height: 100vh;
  align-items: center;
  justify-content: space-evenly;
}

.welcome-text {
  color: rgba(200, 200, 200, 0.9);
  font-size: 5.6rem;
  font-weight: 400;
}

.login-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  width: 96%;
  max-width: 400px;
  padding: 2rem 4rem;
  color: black;
  background-color: rgb(240, 240, 240);
  border-radius: 0.25rem;
  margin-top: 7rem;
}
.extras div:first-of-type {
  display: flex;
  align-items: center;
  gap: 5px;
}
.extras {
  gap: 12px;
  display: flex;
  font-size: 1.2rem;
  justify-content: space-between;
}
.welcome-text-dodgerblue {
  color: white;
  font-size: 8rem;
}
.login-form {

  display: flex;
  flex-direction: column;
  gap: 5px;
}

.login-container button {
  margin-top: 8px;
  background-color: rgba(0, 0, 0, 0.8);
  outline: none;
  border: none;
  font-size: 1.4rem;
  color: white;
  border-radius: 0.25rem;
  padding: 12px 0px;
  justify-self: center;
  cursor: pointer;
}
.login-form button {
  background-color: rgba(0, 0, 0, 0.8);
}

.signupprompt {
  margin-top: 4px;

  font-size: 1.2rem;
  display: flex;
  justify-content: center;
  gap: 10px;
}

.input::placeholder {
}
.input {
  color: black;
  font-size: 1.5rem;
  padding: 12px;
  outline: none;
  border: none;
  border-radius: 0.25rem;
  background-color: rgba(200, 200, 200, 0.9);
  margin-bottom: 1rem;
}
