.upcomingcard-wrapper {
  display: flex;
  flex-direction: column;
  align-items: left;
  height: fit-content;
  margin-top: 3px;
  justify-content: center;
  text-align: left;
  cursor: pointer;
}

.upcomingcard-title {
  font-size: 1.2rem;
  color: white;
  margin-top: 5px;
  font-weight: 300;
  padding: 1rem 2rem;
}

@media screen and (max-width: 380px) {
  .upcomingcard-card {
    height: 40rem;
    width: 24rem;
  }
}

@media screen and (min-width: 380px) {
  .upcomingcard-card {
    height: 40rem;
    width: 24rem;
  }
}
@media screen and (min-width: 390px) {
  .upcomingcard-card {
    height: 40rem;
    width: 28rem;
  }
}
@media screen and (min-width: 440px) {
  .upcomingcard-card {
    height: 40rem;
    width: 30rem;
  }
}
@media screen and (min-width: 460px) {
  .upcomingcard-card {
    /* height: 130px; */
    /* width: 220px; */
  }
}
@media screen and (min-width: 480px) {
  .upcomingcard-card {
    /* height: 150px; */
    /* width: 225px; */
  }
}
@media screen and (min-width: 580px) {
  .upcomingcard-card {
    /* height: 150px; */
    width: 290px;
  }
}
@media screen and (min-width: 650px) {
  .upcomingcard-card {
    /* height: 150px; */
    /* width: 310px; */
  }
}
@media screen and (min-width: 700px) {
  .upcomingcard-card {
    /* height: 150px; */
    /* width: 330px; */
  }
}
@media screen and (min-width: 760px) {
  .upcomingcard-card {
    /* height: 150px; */
    /* width: 350px; */
  }
}
@media screen and (min-width: 820px) {
  .upcomingcard-card {
    /* height: 180px; */
    /* width: 270px; */
  }
  .upcomingcard-title {
    font-size: 1.5rem;
  }
}
@media screen and (min-width: 990px) {
  .upcomingcard-card {
    /* height: 180px; */
    /* width: 320px; */
  }
}
@media screen and (min-width: 1100px) {
  .upcomingcard-card {
    /* height: 200px; */
    /* width: 310px; */
  }
}
@media screen and (min-width: 1190px) {
  .upcomingcard-card {
    /* height: 200px; */
    /* width: 370px; */
  }
}

@media screen and (min-width: 1270px) {
  .upcomingcard-card {
    /* height: 200px; */
    /* width: 310px; */
  }
}
@media screen and (min-width: 1400px) {
  .upcomingcard-card {

  }
}
@media screen and (min-width: 1600px) {
  .upcomingcard-card {
    /* height: 230px; */
    /* width: 360px; */
  }
}
@media screen and (min-width: 1710px) {
  .upcomingcard-card {
    /* height: 240px; */
    /* width: 400px; */
  }
}
@media screen and (min-width: 1750px) {
  .upcomingcard-card {
    /* height: 240px; */
    /* width: 430px; */
  }
}
.upcomingcard-card {
  border-radius: 0.25rem;
  transition: all 0.5s;
  background-position: top;
  background-size: cover;
}

.upcomingcard-wrapper {
  position: relative;
  width: 30rem;
  height: 45rem;
  color: white;
  display: block;
}

.upcomingcard-card {
  position: relative;
  /* top: 0; */
  /* bottom: 0; */
  left: 40px;
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
}

.upcomingcard-title {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  vertical-align: middle;
  justify-content: flex-end;
  flex-wrap: nowrap;
  row-gap: 50px;
  font-weight: 400;

}

.upcomingcard-title p {
  transform: rotate(270deg);
  white-space: nowrap;
  font-size: 17px;
  width: 70rem;
  padding-left: 34rem;
}