.watchlist,
.share {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.animeplayer-container {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
}
.sharewatchlistcontainer {
  background-color: rgba(20, 20, 20, 0.9);
  height: 80px;
  margin-top: 25px;
  justify-content: space-evenly;
  align-items: center;
  display: flex;
  gap: 10px;
  width: 210px;
  border-radius: 0.1rem;
}
.watchlist span,
.share span {
  color: #d8d8d8;
  font-size: 1.2rem;
}
.epindex:hover {
  background-color: #823061;
}
.vime-container {
  width: 96%;
  margin: 1rem auto;
}
.vertical-grid-container {
  display: block;
  position: absolute;
  right: 30px;
  top: 80px;
  width: 29vw;
}
.vertical-grid::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: transparent;
}
.vertical-grid::-webkit-scrollbar {
  width: 5px;
  background-color: transparent;
}
.vertical-grid::-webkit-scrollbar-thumb {
  background-color: #2f2f2f;
  border-radius: 4px;
}
.vertical-grid {
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  max-height: 110rem;
  overflow-x: hidden;
  display: grid;
  overflow-y: scroll;
  row-gap: 15px;
}

.recommend-div button.rec {
  display: none;
}
.top-narrowbar {
  background-color: rgb(38, 50, 56);
  height: 35px;
  border-radius: 3px 3px 0px 0px;
  display: flex;
  align-items: center;
  padding: 0px 15px;
}
.curranimeinfo {
  margin-bottom: 4rem;
  display: flex;
  gap: 25px;
  font-size: 1.6rem;
}
.contindex {
  overflow-y: scroll;
  max-height: 23.6rem;
  height: fit-content;
  display: grid;
  gap: 0.6rem;
  justify-items: start;
  justify-content: start;
  align-items: start;
  grid-template-columns: repeat(auto-fill, minmax(50px, 1fr));
}
.recommendations-title {
  color: white;
  font-size: 1.7rem;
}
.anime-title {
  font-size: 1.8rem;
  color: white;
  font-family: "Poppins";
  font-weight: 500;
}

.cont-in {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  margin-bottom: 1rem;
}

.curranime-status,
.curranime-adaptation,
.curranime-genres,
.curranime-studios {
  font-size: 1.35rem;
  color: #808080;
}
.curranime-genres {
  color: rgba(200, 200, 200, 0.6);
  font-weight: 300;
}
.summary-content {
  text-align: justify;
  color: rgba(200, 200, 200, 0.6);
  font-size: 1.35rem;
  font-weight: 300;
}
.summary-title {
  font-size: 2rem;
  color: rgba(240, 240, 240, 0.9);
  margin: 1rem auto;
  font-weight: 500;
  margin-top: 3rem;
}
.epindex {
  cursor: pointer;
  border-radius: 0.1rem;
  height: 30px;
  width: 50px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.3rem;
}

@media screen and (max-width: 1700px) {
  .vertical-grid .animecard-card {
    height: 280px;
    width: 200px;
  }
}

.curranime-platform,
.curranime-score,
.curranime-epaired,
.curranime-releaseyear {
  color: rgba(200, 200, 200, 0.9);
}
.curranime {
  width: 96.2%;
  padding: 15px;
  background-color: #000000;
  line-height: 1.5;
  border-radius: 0.25rem;
}

@media screen and (max-width: 1500px) {
  .vertical-grid-container {
    display: none;
  }
}
@media screen and (min-width: 768px) {
  .curranimeinfo {
    display: flex !important;
    justify-content: "center" !important;
    align-items: "center" !important;
  }
  .curranime {
    padding-left: 22px;
  }
  .anime-title {
    font-size: 2.6rem;
  }
}
@media screen and (min-width: 1400px) {
  .vime-container {
    margin-left: 22px;
  }
  .animeplayer-container {
    margin-top: 8rem;
    margin-left: 10px;
  }
}
@media screen and (min-width: 1500px) {
  .animeplayer-container {
    max-width: 100vw;
  }
}
