.watchlist-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Center horizontally */
  gap: 1.6rem;
  margin: 0 auto; /* Center the container */
  margin-top: 10rem;
  padding: 1rem 2rem;
  width: 100%; /* Ensure full width */
}

.watchlist-individual-container{
  height: 44rem;
  width: 28rem;
  background-color: rgba(40,40,40,0.4);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 0.2rem;
  gap: 0.5rem;
  padding: 0.5rem 2.8rem;
}

.watchlist-image-innner {
  width: 22rem;
  height: 30rem;
  border-radius: 0.2rem;
}

.watchlist-title {
  color: rgb(223, 221, 221);
}

.watchlist-buttons {
  display: flex;
  gap: 4rem;
}

.watchlist-watch-btn {
  padding: 0.8rem 2.4rem;
  font-size: medium;
  background-color: rgb(10, 10, 10);
  border: none;
  color: rgb(208, 207, 207);
  border-radius: 0.15rem;
  box-shadow: 1px 1px 2px rgba(100,100,100,0.9);
  cursor: pointer;
}

.watchlist-remove-btn {
  padding: 0.8rem 2.4rem;
  font-size: medium;
  background-color: rgb(214, 213, 213);
  border: none;
  color: rgba(10,10,10,0.8);
  border-radius: 0.15rem;
  box-shadow: 1px 1px 4px rgba(0,0,0,0.8);
  cursor: pointer;
}

